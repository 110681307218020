<template>
  <div class="mobile_footer">
    <div class="wallet_address" v-if="store.state.log_in">
      {{ store.state.address.slice(0, 4) }} ****
      {{ store.state.address.slice(-4) }}
    </div>
    <div v-else class="wallet_address" @click="connectToMetamask">
      Connect Wallet
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  setup() {
    const store = inject("store");
    const connectToMetamask = async () => {
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        window.location.reload();
      } else {
        window.open("https://metamask.io/", "_blank");
      }
    };
    return { store, connectToMetamask };
  }
};
</script>

<style scoped>
.mobile_footer {
  height: 55px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wallet_address {
  height: 40px;
  border-radius: 20px;
  border: 1px solid #2c329f;
  color: #444886;
  width: max-content;
}
</style>
