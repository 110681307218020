<template>
  <div v-if="type === 'md'"><Head /> <Panel /></div>
  <div v-else class="mobile_container">
    <Panel />
  </div>
  <MobileFooter v-if="type === 'xs'"/>
</template>

<script>
import Head from "./components/Head.vue";
import Panel from "./components/Panel.vue";
import MobileFooter from "./components/MobileFooter.vue";
import { provide, onMounted, onBeforeUnmount } from "vue";
import store from "@/store";
import useInitProvider from "./hooks/useInitProvider";
import useInitContract from "./hooks/useInitContract";
import useBreakpoint from "./hooks/useBreakPoints";
export default {
  name: "App",
  setup() {
    provide("store", store);
    onMounted(async () => {
      await useInitProvider(store, window.ethereum);
      await useInitContract(store);
    });
    const { type, width } = useBreakpoint();
    onBeforeUnmount(() => {
      window.ethereum.removeListener("accountsChanged", () => {});
      window.ethereum.removeListener("chainChanged", () => {});
    });
    return { type, width };
  },
  components: {
    Head,
    Panel,
    MobileFooter
  }
};
</script>

<style scoped>
.mobile_container {
  height: max-content;
  width: 100%;
  /* overflow-y: auto; */
}
</style>
