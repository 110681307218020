import Web3 from "web3";
import { NETWORKS } from "../constant/network";

export default async function initWeb3(store, provider) {
  if (provider) {
    const web3 = new Web3(provider);
    store.mutations.initProvider(web3);
    const currentAccount = await provider.request({
      method: "eth_accounts"
    });
    if (currentAccount.length > 0) {
      store.mutations.userLogin();
      store.mutations.updateAddress(currentAccount[0]);
      const chainId = await provider.request({
        method: "eth_chainId"
      });
      store.mutations.updateNetworks(NETWORKS[chainId]);
      provider.on("accountsChanged", () => {
        window.location.reload();
      });
      provider.on("chainChanged", () => {
        window.location.reload();
      });
    } else {
      store.mutations.userDisconnect();
    }
  } else {
    throw new Error("Please Install Metamask");
  }
}
