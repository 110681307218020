import BigNumber from "bignumber.js";
import { LP_BYTE, OIN_BYTE } from "../../constant/bit";
export const FetchSysLock = async contract => {
  try {
    const res = await contract.methods.totalToken().call();
    if (res) return new BigNumber(res).div(LP_BYTE).toFixed();
  } catch (error) {
    return "0";
  }
};

export const FetchSysReward = async contract => {
  try {
    const res = await contract.methods.reward().call();
    if (res) return new BigNumber(res).div(OIN_BYTE).toFixed();
  } catch (error) {
    return 0;
  }
};

export const isStakePaused = async contract => {
  try {
    const res = await contract.methods.isStakePaused().call();
    return res;
  } catch (error) {
    return 0;
  }
};

export const isRedeemPaused = async contract => {
  try {
    const res = await contract.methods.isRedeemPaused().call();
    return res;
  } catch (error) {
    return 0;
  }
};

export const FetchSysRewardSpeed = async contract => {
  try {
    const res = await contract.methods.rewardSpeed().call();
    return new BigNumber(res).div(OIN_BYTE).toFixed();
  } catch (error) {
    console.error(error);
  }
};

export const FetchEthGas = async provider => {
  try {
    const gas = await provider.eth.getGasPrice();
    if (gas) {
      return gas;
    }
  } catch (error) {
    console.error(error);
  }
};
