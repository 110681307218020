<template>
  <div class="opera_card">
    <div class="card_form" v-show="title === 1 || title === 2">
      <div class="top_line">
        <p>{{ subtitle }}</p>
        <input
          type="number"
          class="number_input"
          v-model="userInput"
          placeholder="enter emount..."
        />
      </div>
      <div class="bottom_line" v-if="title === 1">
        <p>Balance: {{ balance }} LPToken</p>
        <strong @click="onMaxHandler">Max</strong>
      </div>
      <div class="bottom_line" v-else-if="title === 2">
        <p>Store: {{ lpStore }} LPToken</p>
        <strong @click="onMaxHandler">Max</strong>
      </div>
    </div>
    <div class="reward_detail" v-show="title === 3">
      <div>
        <img alt="oin" src="../assets/img/OIN.png" />
        <p>OIN</p>
      </div>
      <p>{{ store.state.mint_reward }}</p>
    </div>
    <div class="gas_edit">
      <div>
        <p>GasFee</p>
      </div>
      <p>{{ customGas }} Gwei</p>
    </div>
    <button class="confrim_button" @click="onConfirmAction">Confirm</button>
  </div>
</template>

<script>
import { inject, ref, watch, computed } from "vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import { DepositLp, WithdrawLp, Claim } from "../utils/contractHelper/user";
import BigNumber from "bignumber.js";
export default {
  name: "Card",
  props: ["title"],
  setup(props) {
    const subtitle = ref("Deposit");
    const store = inject("store");
    const gasPanelRef = ref(null);
    const userInput = ref("");
    const gasInput = ref("");
    const toast = info => {
      createToast(info, {
        position: "top-center",
        type: "danger",
        transition: "zoom",
        timeout: 2000,
        hideProgressBar: true,
        showIcon: true
      });
    };
    const balance = computed(() => {
      return Number(Number(store.state.lp_balance).toFixed(12));
    });
    const lpStore = computed(() => {
      return Number(Number(store.state.lp_store).toFixed(12));
    });
    const customGas = computed(() => {
      return new BigNumber(store.state.eth_gas).div("1000000000").toFixed(2);
    });

    // passing the getter of props as the first parameter to watch function
    watch(
      () => props.title,
      val => {
        val === 1
          ? (subtitle.value = "Deposit")
          : (subtitle.value = "Withdraw");
        // clear Input area when switch Card
        userInput.value = "";
      }
    );

    watch([userInput, () => props.title], () => {
      if (props.title === 1) {
        if (userInput.value > store.state.lp_balance) {
          toast("Insufficient LPtoken balance");
          userInput.value = "";
        }
      } else if (props.title === 2) {
        if (userInput.value > store.state.lp_store) {
          toast("Insufficient LPtoken store");
          userInput.value = "";
        }
      }
    });

    const onMaxHandler = () => {
      if (props.title === 1) {
        userInput.value = store.state.lp_balance;
      } else if (props.title === 2) {
        userInput.value = store.state.lp_store;
      }
    };

    const onConfirmAction = async () => {
      if (props.title === 1) {
        if (!userInput.value || Number(userInput.value) < 0) {
          userInput.value = "";
          toast("Invalid Input Detected");
        } else {
          if (store.state.deposit_paused) {
            toast("Sorry Deposit has been temporarily shut down");
          } else {
            await DepositLp(
              store.state.provider,
              userInput.value,
              store.state.address,
              customGas.value
            );
            userInput.value = "";
          }
        }
      } else if (props.title === 2) {
        if (!userInput.value || Number(userInput.value) < 0) {
          userInput.value = "";
          toast("Invalid Input Detected");
        } else {
          if (store.state.withdraw_paused) {
            toast("Sorry Withdraw has been temporarily shut down");
          } else {
            await WithdrawLp(
              store.state.provider,
              userInput.value,
              store.state.address,
              customGas.value
            );
            userInput.value = "";
          }
        }
      } else {
        if (store.state.mint_reward === "0") {
          toast("Insufficient Reward Balance");
        } else {
          await Claim(
            store.state.provider,
            store.state.address,
            customGas.value
          );
        }
      }
    };
    return {
      store,
      subtitle,
      balance,
      gasPanelRef,
      lpStore,
      onConfirmAction,
      userInput,
      customGas,
      onMaxHandler,
      gasInput
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../style/_variable.scss";
.opera_card {
  width: 540px;
  height: 340px;
  background: #ffffff;
  box-shadow: 1px 4px 32px 0px #dfe1ff;
  border-radius: 24px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  p {
    color: $font-color;
    font-size: $regular-size;
  }
  @media screen and (max-width: $small) {
    width: 95%;
    margin-bottom: 100px;
    height: 270px;
  }
}

.card_form {
  width: 85%;
  background: #ffffff;
  box-shadow: 1px 1px 10px 0px #e5e7ff;
  border-radius: 10px;
  strong {
    color: $font-color;
    font-size: $regular-size;
    cursor: pointer;
  }
  @media screen and (max-width: $small) {
    width: 90%;
  }
}

.reward_detail {
  display: flex;
  align-items: center;
  width: 85%;
  padding: 30px 15px;
  background: #ffffff;
  box-shadow: 1px 1px 10px 0px #e5e7ff;
  border-radius: 10px;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
  }
  img {
    width: 30px;
    height: auto;
    margin-right: 15px;
  }
}

.gas_edit {
  width: 87%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  position: relative;
  .edit_toggle {
    cursor: pointer;
    margin-left: 15px;
  }
  div {
    display: flex;
  }
}

.top_line,
.bottom_line {
  margin: 15px 0;
  padding: 0 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.number_input {
  outline: none;
  width: 166px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #787991;
  padding-left: 10px;
  font-size: $regular-size;
}
.confrim_button {
  margin-top: 60px;
  width: 85%;
  height: 55px;
  background: #2c329f;
  border-radius: 12px;
  font-size: 20px;
  outline: none;
  border: 0;
  cursor: pointer;
  color: #ffffff;
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  &:active {
    transform: scale(1.02);
  }
  @media screen and (max-width: $small) {
    height: 45px;
    margin-top: 40px;
  }
}
</style>
