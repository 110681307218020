<template>
  <div class="top_bar">
    <img alt="oin_lpstaking" src="../assets/img/logo.png" class="oin_logo" />

    <div class="wallet_address" v-if="store.state.log_in">
      {{ store.state.address.slice(0, 4) }} ****
      {{ store.state.address.slice(-4) }}
      <div class="network_status">{{ store.state.network }}</div>
    </div>
    <div v-else class="wallet_address" @click="connectToMetamask">
      Connect Wallet
    </div>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  name: "Head",
  setup() {
    const store = inject("store");
    const connectToMetamask = async () => {
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        window.location.reload();
      } else {
        window.open("https://metamask.io/", "_blank");
      }
    };
    return { store, connectToMetamask };
  }
};
</script>

<style lang="scss">
@import "../style/_variable.scss";
.top_bar {
  background-color: $el_color;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}

.oin_logo {
  width: 90px;
  height: auto;
}

.wallet_address {
  font-size: 16px;
  color: #ffffff;
  width: max-content;
  height: 50px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
}

.network_status {
  height: 35px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: max-content;
  padding: 0 10px;
  margin-left: 10px;
}
</style>
