import { reactive } from "vue";

const state = reactive({
  log_in: false,
  address: "",
  provider: null,
  network: null,
  lp_balance: "0",
  lp_store: "0",
  mint_reward: "0",
  sys_lp_lock: "0",
  sys_reward: "0",
  sys_reward_speed: "0",
  deposit_paused: false,
  withdraw_paused: false,
  eth_gas: "0"
});

const mutations = {
  userLogin() {
    state.log_in = true;
  },
  userDisconnect() {
    state.log_in = false;
  },
  updateAddress(val) {
    state.address = val;
  },
  initProvider(val) {
    state.provider = val;
  },
  updateNetworks(val) {
    state.network = val;
  },
  updateUserLpBalance(val) {
    state.lp_balance = val;
  },
  updateUserLpStored(val) {
    state.lp_store = val;
  },
  checkDepositPause(val) {
    state.deposit_paused = val;
  },
  checkWithDepositPause(val) {
    state.withdraw_paused = val;
  },
  updateSysLpStored(val) {
    state.sys_lp_lock = val;
  },
  updateSysReward(val) {
    state.sys_reward = val;
  },
  updateUserReward(val) {
    state.mint_reward = val;
  },
  updateSysRewardSpeed(val) {
    state.sys_reward_speed = val;
  },
  updateETHGas(val) {
    state.eth_gas = val;
  }
};

export default { state, mutations };
