import BigNumber from "bignumber.js";
import { LP_BYTE, OIN_BYTE } from "../../constant/bit";
import {
  oinStakeAddress,
  oinStakeAbi,
  OinAbi,
  OinAddress
} from "../../constant/abi";

const BN = BigNumber.clone({ DECIMAL_PLACES: 18 });
const OBN = BigNumber.clone({ DECIMAL_PLACES: 8 });

export const DepositLp = async (provider, amount, address, gas) => {
  try {
    const oinStakeContract = new provider.eth.Contract(
      oinStakeAbi,
      oinStakeAddress
    );
    const oinContract = new provider.eth.Contract(OinAbi, OinAddress);
    const bigCredit = new BigNumber("100000000").times(LP_BYTE).toFixed();
    const bigAmount = new BigNumber(amount).times(LP_BYTE).toFixed();
    await oinContract.methods.approve(oinStakeAddress, bigCredit).send(
      {
        from: address,
        gasPrice: new BigNumber(gas).times("1000000000").toFixed(),
        gasLimit: 700000
      },
      (err, hash) => {
        if (!hash) {
          throw new Error(err);
        } else {
          oinStakeContract.methods.stake(bigAmount).send({
            from: address,
            gasPrice: new BigNumber(gas).times("1000000000").toFixed(),
            gasLimit: 700000
          });
        }
      }
    );
  } catch (error) {
    return 0;
  }
};

export const WithdrawLp = async (provider, amount, address, gas) => {
  const oinStakeContract = new provider.eth.Contract(
    oinStakeAbi,
    oinStakeAddress
  );
  try {
    const bigAmount = new BigNumber(amount).times(LP_BYTE).toFixed();
    await oinStakeContract.methods.redeem(bigAmount).send({
      from: address,
      gasPrice: new BigNumber(gas).times("1000000000").toFixed(),
      gasLimit: 700000
    });
  } catch (error) {
    return 0;
  }
};

export const Claim = async (provider, address, gas) => {
  const oinStakeContract = new provider.eth.Contract(
    oinStakeAbi,
    oinStakeAddress
  );
  try {
    oinStakeContract.methods.claimToken(address).send({
      from: address,
      gasPrice: new BigNumber(gas).times("1000000000").toFixed(),
      gasLimit: 700000
    });
  } catch (error) {
    return 0;
  }
};

export const FetchUserLPBalance = async (contract, address) => {
  try {
    const balance = await contract.methods.balanceOf(address).call();
    if (balance) return new BN(balance).div(LP_BYTE).toFixed();
  } catch (error) {
    return 0;
  }
};

export const FetchUserLPStored = async (contract, address) => {
  try {
    const debt = await contract.methods.debtOf(address).call();
    return new BN(debt).div(LP_BYTE).toFixed();
  } catch (error) {
    return 0;
  }
};

export const FetchUserReward = async (contract, address) => {
  try {
    const reward = await contract.methods.getHolderReward(address).call();
    if (reward) return new OBN(reward).div(OIN_BYTE).toFixed();
  } catch (error) {
    return 0;
  }
};

export const isAdmin = async (contract, address) => {};

export const LqiudateWithdraw = async (provider, address, amount, gas) => {
  const oinStakeContract = new provider.eth.Contract(
    oinStakeAbi,
    oinStakeAddress
  );
  try {
    const bigAmount = new BigNumber(amount).times(LP_BYTE).toFixed();
    await oinStakeContract.methods.oRedeem(bigAmount).send({
      from: address,
      gasPrice: new BigNumber(gas).times("1000000000").toFixed(),
      gasLimit: 700000
    });
  } catch (error) {
    return 0;
  }
};
