import axios from "axios";
import { ref } from "vue";
export default function useRequest(url, apikey) {
  const result = ref(null);
  if (!apikey) {
    axios
      .get(url)
      .then(res => {
        result.value = res.data;
      })
      .catch(err => {
        throw new Error(err);
      });
  } else {
    axios
      .get(url, { params: { apikey } })
      .then(res => {
        result.value = res.data;
      })
      .catch(err => {
        throw new Error(err);
      });
  }
  return result;
}
