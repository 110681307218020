/* eslint-disable no-unused-vars */
import {
  oinStakeAddress,
  oinStakeAbi,
  EsmAbi,
  EsmAddress,
  OinAbi,
  OinAddress
} from "../constant/abi";

import {
  FetchSysLock,
  FetchSysReward,
  isStakePaused,
  isRedeemPaused,
  FetchSysRewardSpeed,
  FetchEthGas
} from "../utils/contractHelper/system";

import {
  FetchUserLPBalance,
  FetchUserLPStored,
  FetchUserReward
} from "../utils/contractHelper/user";

export default async function initContractor(store) {
  const provider = store.state.provider;
  const address = store.state.address;
  if (provider) {
    const OinStake = new provider.eth.Contract(oinStakeAbi, oinStakeAddress);
    const EsmContract = new provider.eth.Contract(EsmAbi, EsmAddress);
    const OinContract = new provider.eth.Contract(OinAbi, OinAddress);
    setInterval(() => {
      FetchEthGas(provider).then(res => {
        store.mutations.updateETHGas(res);
      });
      // Fetch System Data
      FetchSysLock(OinStake).then(res => {
        store.mutations.updateSysLpStored(res);
      });
      isStakePaused(EsmContract).then(res => {
        store.mutations.checkDepositPause(res);
      });
      isRedeemPaused(EsmContract).then(res => {
        store.mutations.checkWithDepositPause(res);
      });
      FetchSysReward(OinStake).then(res => {
        store.mutations.updateSysReward(res);
      });
      FetchSysRewardSpeed(OinStake).then(res => {
        store.mutations.updateSysRewardSpeed(res);
      });
      // Fetch User Data
      if (address) {
        FetchUserLPBalance(OinContract, address).then(res => {
          store.mutations.updateUserLpBalance(res);
        });
        FetchUserReward(OinStake, address).then(res => {
          store.mutations.updateUserReward(res);
        });
        FetchUserLPStored(OinStake, address).then(res => {
          store.mutations.updateUserLpStored(res);
        });
      }
    }, 2000);
  }
}
