<template>
  <div class="_container">
    <div class="stat_block" v-if="type === 'md'">
      <div class="stat_info" id="head">
        <p>Total System Staked</p>
        <p class="data_value">{{ sysLock }}</p>
      </div>
      <div class="stat_info">
        <p>Remaining Rewards</p>
        <p class="data_value">{{ sysReward }}</p>
      </div>
      <div class="stat_info">
        <p>APY</p>
        <p class="data_value">{{ apy }} %</p>
      </div>
      <div class="stat_info" id="tail">
        <p>Current Staked</p>
        <p class="data_value">{{ userStore }}</p>
      </div>
      <div class="stat_info">
        <p>Individual Proportions</p>
        <p class="data_value">{{ porprotion }}%</p>
      </div>
    </div>
    <div v-else class="mobile_stat_block">
      <div>
        <p>System Stored</p>
        <p class="data_value">{{ sysLock }}</p>
      </div>
      <div>
        <p>System Rewards</p>
        <p class="data_value">{{ sysReward }}</p>
      </div>
      <div>
        <p>APY</p>
        <p class="data_value">{{ apy }} %</p>
      </div>
      <div>
        <p>Current Staked</p>
        <p class="data_value">{{ userStore }}</p>
      </div>
      <div>
        <p>Individual Proportions</p>
        <p class="data_value">{{ porprotion }}%</p>
      </div>
    </div>
    <div class="tab_selector">
      <button @click="setSelected(1)" :class="{ active: activeBt === 1 }">
        Stake
      </button>
      <button @click="setSelected(2)" :class="{ active: activeBt === 2 }">
        Redeem
      </button>
      <button @click="setSelected(3)" :class="{ active: activeBt === 3 }">
        Claim
      </button>
    </div>
    <Card :title="selected" />
  </div>
</template>

<script>
import { inject, ref, computed } from "vue";
import Card from "./Card.vue";
import useBreakpoint from "../hooks/useBreakPoints";
import useRequest from "../hooks/useRequest";
import BigNumber from "bignumber.js";
export default {
  name: "Panel",
  setup() {
    const selected = ref(1);
    const activeBt = ref(1);
    const lpPrice = useRequest(process.env.VUE_APP_LP_PRICE, "");
    const oinPrice = useRequest(process.env.VUE_APP_OIN_PRICE, "");
    const store = inject("store");
    const { type } = useBreakpoint();
    const sysLock = computed(() => {
      return Number(Number(store.state.sys_lp_lock).toFixed(12));
    });
    const sysReward = computed(() => {
      return Number(Number(store.state.sys_reward).toFixed(4));
    });
    const userStore = computed(() => {
      return Number(Number(store.state.lp_store).toFixed(12));
    });
    // ----apy formula:  lptoken price * block number per year * rewardSpeed / lp-token store * lp-token price
    const apy = computed(() => {
      if (lpPrice.value && oinPrice.value && store.state.sys_lp_lock > 0) {
        return new BigNumber("2102400")
          .times(new BigNumber(oinPrice.value))
          .times(new BigNumber(store.state.sys_reward_speed))
          .times(new BigNumber("100"))
          .div(
            new BigNumber(store.state.sys_lp_lock).times(
              new BigNumber(lpPrice.value)
            )
          )
          .toFixed(2);
      } else {
        return 0;
      }
    });
    const porprotion = computed(() => {
      if (store.state.sys_lp_lock === "0") {
        return 0;
      } else {
        return ((store.state.lp_store / store.state.sys_lp_lock) * 100).toFixed(
          2
        );
      }
    });
    const setSelected = id => {
      selected.value = id;
      activeBt.value = id;
    };
    return {
      store,
      setSelected,
      selected,
      activeBt,
      porprotion,
      sysLock,
      sysReward,
      userStore,
      type,
      apy
    };
  },
  components: {
    Card
  }
};
</script>

<style lang="scss" scoped>
@import "../style/_variable.scss";

._container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.stat_block {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 60px;
  .stat_info {
    width: 250px;
    height: 120px;
    border: 1px solid #bebebe;
    border-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #head {
    border: 1px solid #bebebe;
    border-left: 6px solid #2c329f;
    border-radius: 8px 0px 0px 8px;
  }
  #tail {
    border-left: 6px solid #2c329f;
  }
  p {
    color: $font-color;
    font-size: $regular-size;
  }
  .data_value {
    margin-top: 15px;
    font-size: $mid_size;
  }
}

.mobile_stat_block {
  width: 95%;
  height: max-content;
  background: #ffffff;
  box-shadow: 1px 1px 10px 0px #e5e7ff;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  div {
    width: 45%;
    height: 85px;
    border-radius: 10px;
    border: 1px solid #9193ac;
    margin: 0 0 10px 3.3%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  p {
    color: $font-color;
    font-size: 3.5vw;
  }
  .data_value {
    margin-top: 10px;
    font-size: 4.5vw;
  }
}

.tab_selector {
  margin-top: 85px;
  width: max-content;
  background: #d5d6ec;
  border-radius: 110px;
  button {
    outline: none;
    border: 0;
    color: $font-color;
    background: transparent;
    font-size: $regular-size;
    width: 180px;
    height: 50px;
    cursor: pointer;
  }
  .active {
    background: #2c329f;
    border-radius: 110px;
    color: #ffffff;
  }
  @media screen and (max-width: $small) {
    margin-top: 20px;
    width: 95%;
    button {
      width: 33.3333%;
      height: 45px;
    }
  }
}
</style>
